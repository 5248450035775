export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは4月より%d点上がりました。その調子です！<br>
      6月のテーマは、<b>❝梅雨時期の体調崩れに要注意❞</b>です。気温や気圧の変化が大きく、だるさや不調を感じやすいです。「朝ごはんを食べる」、「ストレッチをする」、「お風呂に浸かる」など、1つだけでも良いので何か気にかけてみましょう。&wellで配信されるコンテンツも上手く活用してくださいね。
      `,
      down: `生活習慣スコアは4月より%d点下がりました。食事・運動など、どの項目に変化がありましたか？<br>
      6月のテーマは、<b>❝梅雨時期の体調崩れに要注意❞</b>です。気温や気圧の変化が大きく、だるさや不調を感じやすいです。「朝ごはんを食べる」、「ストレッチをする」、「お風呂に浸かる」など、1つだけでも良いので何か気にかけてみましょう。&wellで配信されるコンテンツも上手く活用してくださいね。
      `,
      equal100: `生活習慣スコアは4月に引き続き%満点%です。大変素晴らしいです！<br>
      6月のテーマは、<b>❝梅雨時期の体調崩れに要注意❞</b>です。気温や気圧の変化が大きく、だるさや不調を感じやすいです。「朝ごはんを食べる」、「ストレッチをする」、「お風呂に浸かる」など、1つだけでも良いので何か気にかけてみましょう。&wellで配信されるコンテンツも上手く活用してくださいね。
      `,
      equal: `生活習慣スコアは4月と同じ点数です。<br>
      6月のテーマは、<b>❝梅雨時期の体調崩れに要注意❞</b>です。気温や気圧の変化が大きく、だるさや不調を感じやすいです。「朝ごはんを食べる」、「ストレッチをする」、「お風呂に浸かる」など、1つだけでも良いので何か気にかけてみましょう。&wellで配信されるコンテンツも上手く活用してくださいね。
      `,
    },
    steps: {
      up: `歩数スコアは4月より%d点上がりました。<br>
      6月27日（火）から<b>Walk 個人チャレンジ</b>が始まります。歩いた歩数によって、もらえる賞品がランクアップする「歩数賞」など新ルールも！詳細は<a href="https://go.andwell.jp/notifications">お知らせ</a>からご確認ください。
      `,
      down: `歩数スコアは4月より%d点下がりました<br>
      6月27日（火）から<b>Walk 個人チャレンジ</b>が始まります。歩いた歩数によって、もらえる賞品がランクアップする「歩数賞」など新ルールも！詳細は<a href="https://go.andwell.jp/notifications">お知らせ</a>からご確認ください。
      `,
      equal: `歩数スコアは4月と同じ点数です。<br>
      6月27日（火）から<b>Walk 個人チャレンジ</b>が始まります。歩いた歩数によって、もらえる賞品がランクアップする「歩数賞」など新ルールも！詳細は<a href="https://go.andwell.jp/notifications">お知らせ</a>からご確認ください。
      `,
    },
    exercise: {
      up: `運動のスコアは4月より%d点上がりました。<br>
      雨が多くなる時期は、テレビを見ながら<b>踏み台昇降</b>など室内でできる運動もオススメです。`,
      down: `運動のスコアは4月より%d点下がりました。<br>
      雨が多くなる時期は、テレビを見ながら<b>踏み台昇降</b>など室内でできる運動もオススメです。`,
      equal: `運動のスコアは4月と同じ点数です。<br>
      雨が多くなる時期は、テレビを見ながら<b>踏み台昇降</b>など室内でできる運動もオススメです。`,
    },
    meal: {
      up: `食事のスコアは4月より%d点上がりました。<br>
      実は湿度が高くなる梅雨の時期から<b>熱中症</b>に注意が必要！こまめな水分補給を心がけてください。`,
      down: `食事のスコアは4月より%d点下がりました。<br>
      実は湿度が高くなる梅雨の時期から<b>熱中症</b>に注意が必要！こまめな水分補給を心がけてください。`,
      equal: `食事のスコアは4月と同じ点数です。<br>
      実は湿度が高くなる梅雨の時期から<b>熱中症</b>に注意が必要！こまめな水分補給を心がけてください。`,
    },
    drinking: {
      up: `飲酒のスコアは4月より%d点上がりました。<br>
      飲み過ぎたと感じる日が減ってきていますね。寝起きがスッキリしたや、仕事のパフォーマンスが上がったなど体の変化を感じることはありますか？
      `,
      down: `飲酒のスコアは4月より%d点下がりました。<br>
      5月は飲み過ぎたと感じる日が多かったようです。どんな時にたくさん飲んでしまうかなど振り返ってみましょう。
      `,
      equal: `飲酒のスコアは4月と同じ点数です。<br>
      二日酔いの経験はりますか？<a href="https://go.andwell.jp/library/c6e5afe5-d2d2-11ed-8e3c-06528b823331">こちらのコラム</a>では二日酔いになるメカニズムや身体を労わるおつまみの選び方を紹介しています。
      `,
    },
    sleep: {
      up: `睡眠のスコア4月より%d点上がりました。<br>
      ジメジメして寝苦しくなる時期になりますね。<br>
      換気をしたり、エアコンで除湿をして、湿度対策！`,
      down: `睡眠のスコアは4月より%d点下がりました。<br>
      ジメジメして寝苦しくなる時期になりますね。<br>
      換気をしたり、エアコンで除湿をして、湿度対策！`,
      equal: `睡眠のスコアは4月と同じ点数です。<br>
      ジメジメして寝苦しくなる時期になりますね。<br>
      換気をしたり、エアコンで除湿をして、湿度対策！`,
    },
    stress: {
      up: `ストレスのスコアは4月より%d点上がりました。<br>
      ストレス反応は、<b>心理面</b>（イライラなど）・<b>身体面</b>（食欲低下など）・<b>行動面</b>（仕事のミスなど）の3つに分けられます。身体からのサインを見逃さないように！
      `,
      down: `ストレスのスコアは4月より%d点下がりました。<br>
      ストレス反応は、<b>心理面</b>（イライラなど）・<b>身体面</b>（食欲低下など）・<b>行動面</b>（仕事のミスなど）の3つに分けられます。身体からのサインを見逃さないように！
      `,
      equal: `ストレスのスコアは4月と同じ点数です。<br>
      ストレス反応は、<b>心理面</b>（イライラなど）・<b>身体面</b>（食欲低下など）・<b>行動面</b>（仕事のミスなど）の3つに分けられます。身体からのサインを見逃さないように！
      `,
    },
  },
  en: {
    score: {
      up: `Your Lifestyle Score has gone up %d points since April. Keep up the good work!<br>
      The theme for June is <b>Watch Out for Health Problems during the Rainy Season</b>. Temperature and air pressure fluctuate greatly, and it's easy to feel sluggish and unwell. Let's try to focus on something, even if it's just one thing, such as "eating breakfast," "stretching," or "soaking in the bath." Be sure to make good use of the contents sent out by &well too.`,
      down: `Your Lifestyle score has dropped by %d points since April. Which items have changed, e.g., diet, exercise, etc.?<br>
      The theme for June is <b>Watch Out for Health Problems during the Rainy Season</b>. Temperature and air pressure fluctuate greatly, and it's easy to feel sluggish and unwell. Let's try to focus on something, even if it's just one thing, such as "eating breakfast," "stretching," or "soaking in the bath." Be sure to make good use of the contents sent out by &well too.`,
      equal100: `Your Lifestyle Score is perfect, as it was in April. Excellent work!<br>
      The theme for June is <b>Watch Out for Health Problems during the Rainy Season</b>. Temperature and air pressure fluctuate greatly, and it's easy to feel sluggish and unwell. Let's try to focus on something, even if it's just one thing, such as "eating breakfast," "stretching," or "soaking in the bath." Be sure to make good use of the contents sent out by &well too.`,
      equal: `Your Lifestyle Score is the same score as in April.<br>
      The theme for June is <b>Watch Out for Health Problems during the Rainy Season</b>. Temperature and air pressure fluctuate greatly, and it's easy to feel sluggish and unwell. Let's try to focus on something, even if it's just one thing, such as "eating breakfast," "stretching," or "soaking in the bath." Be sure to make good use of the contents sent out by &well too.`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since April.<br>
      Individual Walk Challenge will begin on Tuesday, June 27. There are new rules, like with the "Steps Award," in which the prize ranks up depending on the number of steps taken! For details, please check the <a href="https://go.andwell.jp/notifications">Notifications</a>.
      `,
      down: `Your steps score has gone down %d pts since April. <br>
      Individual Walk Challenge will begin on Tuesday, June 27. There are new rules, like with the "Steps Award," in which the prize ranks up depending on the number of steps taken! For details, please check the <a href="https://go.andwell.jp/notifications">Notifications</a>.
      `,
      equal: `Your steps score is the same as April. <br>
      Individual Walk Challenge will begin on Tuesday, June 27. There are new rules, like with the "Steps Award," in which the prize ranks up depending on the number of steps taken! For details, please check the <a href="https://go.andwell.jp/notifications">Notifications</a>.
      `,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since April.<br>
      In the rainy season, we also recommend exercise you can do indoors, like <b>step aerobics</b> while watching TV.
      `,
      down: `Your exercise score has gone down %d pts since April.<br>
      In the rainy season, we also recommend exercise you can do indoors, like <b>step aerobics</b> while watching TV.
      `,
      equal: `Your exercise score is the same as April.<br>
      In the rainy season, we also recommend exercise you can do indoors, like <b>step aerobics</b> while watching TV.
      `,
    },
    meal: {
      up: `Your food score has gone up %d pts since April.<br>
      In fact, once the humidity starts in the rainy season, you'll also need to be careful about <b>heat stroke</b>! 
      Make sure you rehydrate regularly.
      `,
      down: `Your food score has gone down %d pts since April.<br>
      In fact, once the humidity starts in the rainy season, you'll also need to be careful about <b>heat stroke</b>! 
      Make sure you rehydrate regularly.
      `,
      equal: `Your food score is the same as April.<br>
      In fact, once the humidity starts in the rainy season, you'll also need to be careful about <b>heat stroke</b>! 
      Make sure you rehydrate regularly.
      `,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since April.<br>
      The number of days you felt you had too much to drink went down. Do you feel any changes, such as waking up refreshed or improved performance at work?
      `,
      down: `Your alcohol score has gone down %d pts since April.<br>
      It seems that there were many days in May when you felt you drank too much. Let's look back and see when you drank a lot.
      `,
      equal: `Your alcohol score is the same as April.<br>
      Have you ever had a hangover? <a href="https://go.andwell.jp/library/c6e5afe5-d2d2-11ed-8e3c-06528b823331">This column</a> introduces how hangovers work and how to choose snacks that will help you feel better.
      `,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since April.<br>
      We're heading into the season of restless, humid nights. 
      Beat the humidity by ventilating the room and using the air conditioning to dehumidify!
      `,
      down: `Your sleep score has gone down %d pts since April.<br>
      We're heading into the season of restless, humid nights. 
      Beat the humidity by ventilating the room and using the air conditioning to dehumidify!
      `,
      equal: `Your sleep score is the same as April.<br>
      We're heading into the season of restless, humid nights. 
      Beat the humidity by ventilating the room and using the air conditioning to dehumidify!
      `,
    },
    stress: {
      up: `Your stress score has gone up %d pts since April.<br>
      Stress reactions can be divided into three categories: <b>psychological</b> (e.g., irritability), <bphysical</b> (e.g., loss of appetite), and <b>behavioral</b> (e.g., mistakes at work). Don't overlook signs from your body!
      `,
      down: `Your stress score has gone down %d pts since April.<br>
      Stress reactions can be divided into three categories: <b>psychological</b> (e.g., irritability), <bphysical</b> (e.g., loss of appetite), and <b>behavioral</b> (e.g., mistakes at work). Don't overlook signs from your body!
      `,
      equal: `Your stress score is the same as April.<br>
      Stress reactions can be divided into three categories: <b>psychological</b> (e.g., irritability), <bphysical</b> (e.g., loss of appetite), and <b>behavioral</b> (e.g., mistakes at work). Don't overlook signs from your body!
      `,
    },
  },
}
